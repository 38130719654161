import React from "react";
import { BackgroundLines } from "../@/components/ui/background-lines";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  return (
    <>
      <Link to="/">
        <h2 className="text-left ml-4 pt-2 text-white text-2xl md:text-3xl lg:text-3xl font-bold font-bricolage ">
          Vansha
        </h2>
      </Link>
      <BackgroundLines className="relative flex h-screen items-center justify-center w-full flex-col px-4">
        <p className="bg-clip-text text-transparent text-center bg-gradient-to-b  from-neutral-600 to-white text-base md:text-lg lg:text-xl font-sans   relative z-10 font-bold tracking-tight">
          A new makeover
        </p>
        <h2 className="bg-clip-text text-transparent text-center bg-gradient-to-b  from-neutral-600 to-white text-4xl md:text-4xl lg:text-7xl font-sans py-2 md:py-10 relative z-10 font-bold tracking-tight">
          Coming Soon..
          <br />
        </h2>
        <p className="max-w-xl mx-auto text-sm md:text-lg font-sans bg-clip-text text-transparent bg-gradient-to-t from-neutral-600 to-white text-center pb-16">
          Empowering Growth with Cutting-Edge Software & AI Solutions
        </p>
      </BackgroundLines>
    </>
  );
};

export default ComingSoon;
