import React from "react";
import { EmailIcon, LinkedInIcon } from "../Icons/index";
import { PhoneIcon } from "lucide-react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className=" p-8 relative z-50">
      {/* <div className="flex flex-col md:flex-row items-center justify-between gap-2">
        <div className="text-white z-20">
          Contact Us at{" "}
          <a href="mailto: info.vanshatech@gmail.com">
            info.vanshatech@gmail.com
          </a>
        </div>
        <div className="text-white">
          Vanshatech © 2024 | All Rights Reserved
        </div>
      </div> */}
      <div className=" flex flex-col gap-0 lg:gap-12 pb-5 md:flex-col items-center justify-between ">
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-0 lg:justify-between w-full">
          {/* Logo and Description */}
          <div className="space-y-3">
            <h2 className="text-white text-3xl lg:text-4xl font-extrabold font-bricolage">
              Vansha
            </h2>
            <p className="text-gray-400 text-sm max-w-[300px]">
              Empowering Brands with Innovative and Seamless Design and
              Development
            </p>
            {/* Social as */}
            <div className="space-y-4">
              <div className="flex gap-4">
                {/* <a
                  href="#"
                  className="text-gray-400 hover:text-white p-2 rounded-full border"
                >
                  <TwitterIcon />
                </a> */}
                <a
                  title="Linkedin page link"
                  href="https://www.linkedin.com/company/vanshatech"
                  className="text-gray-400 hover:text-white p-2 rounded-full border "
                >
                  <LinkedInIcon />
                </a>
              </div>
              <div className="flex flex-col sm:flex-row gap-3 sm:gap-5 z-50">
                <p className="text-gray-400 text-sm flex items-center gap-2 hover:text-white">
                  <PhoneIcon className="w-4 h-4 " />
                  <a title="Phone number" href="tel:08552815676">
                    +91 8552815676
                  </a>
                </p>
                <p className="text-gray-400 text-sm flex items-center gap-2 hover:text-white">
                  <EmailIcon />
                  <a href="mailTo:info.vanshatech@gmail.com">
                    info.vanshatech@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="flex flex-col gap-4 sm:flex-row sm:justify-between items-center pt-10 w-full relative">
          <div className="hidden md:block h-[1px] w-full sm:w-[10%] bg-white/15 absolute top-0 left-0"></div>
          <div className="flex gap-4">
            <Link
              to="privacy-policy"
              className="text-gray-400 hover:text-white text-sm"
            >
              Privacy Policy
            </Link>
            <Link
              to="terms-conditions"
              className="text-gray-400 hover:text-white text-sm"
            >
              Terms & Conditions
            </Link>
          </div>
          <p className="text-gray-400 text-sm text-center sm:text-left">
            © {currentYear} Vanshatech - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
