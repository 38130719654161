import { Route, Routes } from "react-router-dom";
import Header from "../components/header";
import Home from "../screens/home";
import Service from "../screens/service";
import Contact from "../screens/contact";
import Footer from "../components/footer";
import Blogs from "../screens/blogs";
import ComingSoon from "../screens/comingSoon";
import PrivacyPolicy from "../screens/privacyPolicy";
import TermsAndCondtitons from "../screens/termsAndConditions";

const Layout = () => {
  return (
    <>
      <div className="h-full w-full !bg-black  z-100">
        {/* <Header /> */}
        {/* <ComingSoon /> */}
        <Routes>
          {/* <Route path="/" element={<Home />} />
          <Route path="service" element={<Service />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} /> */}
          <Route path="/" element={<ComingSoon />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-conditions" element={<TermsAndCondtitons />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
