import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <Link to="/">
        <h2 className="text-left ml-4 pt-2 text-white text-2xl md:text-lg font-semibold lg:text-3xl font-bold font-bricolage ">
          Vansha
        </h2>
      </Link>
      <section
        id="privacy"
        className="p-8  md:py-12 md:px-24 lg:px-24 space-y-12 lg:space-y-20"
      >
        <div className="flex flex-col gap-[20px] items-center">
          <span className="text-4xl font-bricolage text-[#11C483]">
            Privacy Policy
          </span>
          {/* <p className="text-3xl font-dm-sans">
          We Innovate digital solutions with a touch of creativity
        </p> */}
        </div>
        <div className="flex flex-col gap-[20px] font-sans  text-white">
          <p>
            At VanshaTech, we prioritize your privacy. This Privacy Policy
            outlines how we collect, use, and protect your information in
            compliance with Indian laws.
          </p>
          <p>
            <h3 className="text-lg font-semibold">
              {" "}
              1. Information We Collect
            </h3>{" "}
            <br />
            We collect the following types of data:
            <ul className="ml-4">
              <li>
                A. Personal Information: Name, email, phone number and address.
              </li>
              <li>
                B. Financial Information: Payment details for transaction
                purposes.
              </li>
              <li>C. Technical Data: IP address, device type, and cookies.</li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">
              2. Purpose of Data Collection
            </h3>
            <br /> We collect and use your data for the following purposes:
            <ul className="ml-4">
              <li> • To deliver our services and fulfill orders.</li>
              <li>• For fraud prevention and security. </li>
              <li>
                • To comply with Indian laws, including data protection and
                anti-piracy laws.
              </li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">3. Data Sharing</h3>
            <br /> We do not sell, rent, or trade your data.
            <ul className="ml-4">
              <li>
                • Data may be shared with government authorities as required by
                law.
              </li>
              <li>
                • Third parties, like payment processors and logistics partners,
                receive data only for specific operational purposes.
              </li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">4. Data Protection</h3>
            <br /> We adhere to ISO/IEC 27001 standards to safeguard user data,
            including encryption techniques and secure data storage practices.
          </p>
          <p>
            <h3 className="text-lg font-semibold">5. Your Rights </h3>
            <br />
            As a user, you have the right to:
            <ul className="ml-4">
              <li>A. Access and review the personal data we hold about you.</li>
              <li>B. Request corrections to inaccuracies.</li>
              <li>
                C. Request deletion of your data under the Right to Be Forgotten
                provision.
              </li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">6. Cookie Policy</h3>
            <br />
            <ul className="ml-4">
              <li>• VanshaTech uses cookies to improve user experience.</li>
              <li>
                • You can disable cookies via your browser settings, though some
                features may be affected.
              </li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">7. Third-Party Services</h3>
            <br /> Our website may link to third-party services. VanshaTech is
            not responsible for their privacy practices or policies.
          </p>
          <p>
            <h3 className="text-lg font-semibold">8. Data Retention</h3>
            <br /> We retain your data only for as long as necessary for:
            <ul className="ml-4">
              <li>• Delivering services.</li>
              <li>• Legal compliance under Indian laws.</li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">9. Children’s Privacy</h3>
            <br /> We do not knowingly collect personal data from children under
            13 years without parental consent.
          </p>
          <p>
            <h3 className="text-lg font-semibold">10. Grievance Redressal</h3>
            <br /> If you have any concerns about this policy, please contact
            our Grievance Officer: <br /> Name: Dhananjay Pawar <br /> Email:{" "}
            <a
              href="mailTo:info.vanshatech@gmail.com"
              className="hover:underline"
            >
              info.vanshatech@gmail.com
            </a>
            <br /> Phone:{" "}
            <a
              title="Phone number"
              href="tel:08552815676"
              className="hover:underline"
            >
              +91 8552815676
            </a>
          </p>
          <p>
            <h3 className="text-lg font-semibold">11. Amendments</h3>
            <br /> VanshaTech may update this Privacy Policy from time to time.
            Changes will be communicated via website notices, Linkedin or email.
          </p>
          <p>
            <h3 className="text-lg font-semibold">12. Governing Law</h3>
            <br /> This Privacy Policy is governed by the Information Technology
            Act, 2000, and other relevant Indian laws.
          </p>
          <p>
            For more details, email us at{" "}
            <a
              href="mailTo:info.vanshatech@gmail.com"
              className="hover:underline"
            >
              info.vanshatech@gmail.com
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
