import React from "react";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <>
      <Link to="/">
        <h2 className="text-left ml-4 pt-2 text-white text-2xl md:text-lg lg:text-3xl font-bold font-bricolage ">
          Vansha
        </h2>
      </Link>
      <div className="p-8  md:py-12  md:px-24 lg:px-24 space-y-12 lg:space-y-20">
        <div className="flex flex-col gap-[20px] items-center">
          <span className="text-4xl font-bricolage text-[#11C483]">
            Terms & Conditions
          </span>
          {/* <p className="text-3xl font-dm-sans">
      We Innovate digital solutions with a touch of creativity
    </p> */}
        </div>
        <div className="flex flex-col gap-[20px] font-sans  text-white">
          <p>
            Welcome to VanshaTech! By accessing or using our services, you agree
            to comply with these Terms and Conditions (T&Cs). Please read them
            carefully before proceeding.
          </p>
          <p>
            <h3 className="text-lg font-semibold">1. Introduction</h3>
            <br />
            These T&Cs govern the use of VanshaTech's website, services, and
            products. By using our services, you acknowledge and accept these
            terms. If you disagree with any part, you may not access our
            services.
          </p>
          <p>
            <h3 className="text-lg font-semibold">2. Eligibility</h3>
            <br />
            • You must be 18 years or older to access our services. <br />• If
            you are below 18, ensure you have parental or guardian consent.
          </p>
          <p>
            <h3 className="text-lg font-semibold">3. User Responsibilities</h3>
            <br />
            • Provide Accurate Information: Ensure all data shared during
            registration is accurate and up to date. <br />
            • Use Services Lawfully: Avoid activities that breach any laws,
            including copyright and labor laws. <br />• Maintain
            Confidentiality: Safeguard your account credentials. You are
            responsible for all activities under your account.
          </p>
          <p>
            <h3 className="text-lg font-semibold">4. Payments and Refunds</h3>
            <br />
            • Payments: Payment terms, including pricing and billing cycles,
            will be outlined during the purchase process. <br />• Refund Policy:
            Refunds will be issued as per our Refund Policy.
          </p>
          <p>
            <h3 className="text-lg font-semibold">5. Prohibited Activities</h3>
            <br />
            Users of VanshaTech's services are strictly prohibited from:
            <ul className="ml-4">
              <li>
                1. Engaging in fraudulent activities or unauthorized data
                access.
              </li>
              <li>2. Distributing copyrighted or pirated content.</li>
              <li>
                3. Violating Indian Labour Laws or engaging in unethical
                employment practices.
              </li>
              <li>
                4. Spreading malicious software, including viruses or spyware.
              </li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">6. Intellectual Property</h3>
            <br />
            • Ownership: All content, including text, graphics, and software, is
            owned by VanshaTech or its licensors. <br />• Usage: Reproduction,
            distribution, or commercial use of our content is prohibited without
            prior written consent.
          </p>
          <p>
            <h3 className="text-lg font-semibold">7. Indemnity Clause</h3>
            <br />
            You agree to indemnify VanshaTech against any claims, damages, or
            expenses arising from:
            <ul className="ml-4">
              <li>• Violation of these T&Cs.</li>
              <li>• Unauthorized use of our services.</li>
              <li>
                • Non-compliance with applicable laws, including labour and
                copyright regulations.
              </li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">
              8. Limitation of Liability
            </h3>
            <br />• VanshaTech shall not be held liable for:
            <ul className="ml-4">
              <li>• Indirect, incidental, or consequential damages.</li>
              <li>• Loss of data unless caused by our proven negligence.</li>
            </ul>
            • Use of our services is at your sole risk.
          </p>
          <p>
            <h3 className="text-lg font-semibold">9. Termination</h3>
            <br />
            VanshaTech reserves the right to terminate or suspend accounts in
            case of:
            <ul className="ml-4">
              <li>• Violation of these T&Cs.</li>
              <li>• Any activity deemed harmful or illegal.</li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">10. Dispute Resolution</h3>
            <br />
            • All disputes will be resolved through arbitration as per the
            Arbitration and Conciliation Act, 1996. <br />• The courts of
            Ponda-Goa, India, will have exclusive jurisdiction.
          </p>
          <p>
            <h3 className="text-lg font-semibold">11. Governing Law</h3>
            <br />
            These terms are governed by the laws of India, including but not
            limited to:
            <ul className="ml-4">
              <li>• Information Technology Act, 2000.</li>
              <li>• Applicable Labour Laws and Intellectual Property Laws.</li>
            </ul>
          </p>
          <p>
            <h3 className="text-lg font-semibold">12. Amendments</h3>
            <br />
            VanshaTech reserves the right to modify these terms at any time.
            Users will be notified of significant changes via email or website
            notices.
          </p>
          <p>
            For any questions or clarifications, please contact us at{" "}
            <a
              href="mailTo:info.vanshatech@gmail.com"
              className="hover:underline"
            >
              info.vanshatech@gmail.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
